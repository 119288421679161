<template>
    <div class="DailySettleCreate" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form ref="form" :model="form" :inline="true" label-width="100px" size="small">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="交易机构">
                            <el-select
                                placeholder="请选择"
                                v-model="form.deptCode"
                                @change="handleSelectDeptOrDate"
                                style="width: 200px"
                                filterable
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="dept in depts"
                                    :label="dept.name"
                                    :value="dept.code"
                                    :key="dept.code"
                                />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="结算日期">
                            <el-date-picker
                                style="width: 200px"
                                v-model="form.date"
                                type="date"
                                value-format="yyyy-MM-dd"
                                placeholder="选择日期"
                                :picker-options="pickerOptions"
                                @change="handleSelectDeptOrDate"
                            />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="收银员">
                            <el-select
                                v-if="hasPrivilege('biz.finance.daily.settle.all')"
                                placeholder="请选择"
                                v-model="form.cashierCode"
                                @change="handleSelectStaff"
                                filterable
                                style="width: 200px"
                            >
                                <el-option value="" label="请选择" />
                                <el-option
                                    v-for="staff in meta.staffs"
                                    :label="staff.realName"
                                    :value="staff.code"
                                    :key="staff.code"
                                />
                            </el-select>
                            <el-input v-else placeholder="" readOnly="true" v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="创建人">
                            <el-input placeholder="" readOnly="true" v-model="form.creator" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <hr />
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="收银笔数">
                            <el-input placeholder="" readOnly="true" v-model="form.tradeCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="原价总计">
                            <el-input placeholder="" readOnly="true" v-model="form.totalOriginalPrice" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="优惠汇总">
                            <el-input placeholder="" readOnly="true" v-model="form.totalFavour" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="抹零汇总">
                            <el-input placeholder="" readOnly="true" v-model="form.totalFloor" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="应收总额">
                            <el-input placeholder="" readOnly="true" v-model="form.totalPay" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <hr />
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="退款笔数">
                            <el-input placeholder="" readOnly="true" v-model="form.refundCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="应退总额">
                            <el-input placeholder="" readOnly="true" v-model="form.needRefund" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="整单优惠汇总">
                            <el-input placeholder="" readOnly="true" v-model="form.refundPreference" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="实退总额">
                            <el-input placeholder="" readOnly="true" v-model="form.totalRefund" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="(自)退款笔数">
                            <el-input placeholder="" readOnly="true" v-model="form.selfRefundCount" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="(自)实退总额">
                            <el-input placeholder="" readOnly="true" v-model="form.totalSelfRefund" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <hr />
                <el-row>
                    <el-col :span="24">
                        <ef-remark v-model="form.remark" />
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table border style="width: 100%" :data="tableData">
                <el-table-column label="序号" width="60" type="index" fixed="left" />
                <el-table-column label="支付类型" width="200" prop="templateName" />
                <el-table-column label="应收总额" width="144" prop="money">
                    <template slot-scope="scope"
                        ><span>{{ scope.row.money | money }}</span></template
                    >
                </el-table-column>
                <el-table-column label="实收总额" width="144">
                    <template slot-scope="scope">
                        <ef-price-input v-model="scope.row.pays" :min="-Infinity" />
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-button :disabled="disableSave" type="primary" @click="handleSave" size="small">保存</el-button>
        </el-card>
    </div>
</template>

<script>
import UrlUtils from 'js/UrlUtils';
import MoneyUtil from 'js/MoneyUtils';
import Util from 'js/Util';
import EfRemark from 'components/EfRemark';
import EfPriceInput from 'components/EfPriceInput';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'DailySettleCreate',
    components: { EfPriceInput, EfRemark },
    data() {
        return {
            canChangeCashier: this.hasPrivilege('biz.finance.daily.settle.all'),
            tableData: [],
            form: {
                creator: this.$store.state.session.name,
                deptCode: '',
                cashierCode: '',
                date: this.formatDate(new Date()),
                tradeCount: '',
                refundCount: '',
                totalOriginalPrice: '',
                totalFavour: '',
                totalFloor: '',
                totalPay: '',
                needRefund: '',
                refundPreference: '',
                totalRefund: '',
                selfRefundCount: '',
                totalSelfRefund: '',
                remark: '',
                tradeIds: [],
                refundIds: [],
                selfRefundIds: [],
            },
            url: {
                queryStaff: '/finance/settle/daily/cashier',
                queryDetail: '/finance/settle/daily/calc',
                save: '/finance/settle/daily/settle',
                afterSaveQuery: '/trade/cash/error/afterSaveQuery/',
            },
            meta: {
                staffs: [],
            },
            pickerOptions: {
                disabledDate: (time) => {
                    //不能大于当前日期
                    return time.getTime() > Date.now();
                },
            },
            depts: [],
            disableSave: false,
        };
    },
    mounted() {
        const _this = this;
        if (!_this.canChangeCashier) {
            _this.form.cashierCode = _this.$store.state.session.code;
        }
        this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE).then((rst) => {
            this.depts = rst;
            if (rst.length == 1) {
                _this.form.deptCode = rst[0].code;
                _this.queryStaff();
                _this.sureDeptHaveNoSyncTrade();
            }
        });
    },
    methods: {
        handleSave() {
            const _this = this;
            if (!_this.form.remark) {
                _this.$message.error('请填写备注并不大于200字');
                return;
            }
            if (!_this.tableData || _this.tableData.length <= 0) {
                _this.$message.error('当前不存在需要结算的数据');
                return;
            }
            const pays = [];
            const templateCodes = [];
            for (const value of this.tableData) {
                if (value.pays == null || value.pays == undefined) {
                    _this.$message.error('请填写' + value.templateName + '的实收总额');
                    return;
                }
                const temMoney = MoneyUtil.moneyToDb(value.pays);
                templateCodes.push(value.templateCode);
                pays.push(temMoney);
            }
            const _params = {
                templateCode: templateCodes,
                pays: pays,
                cashier: _this.form.cashierCode,
                deptCode: _this.form.deptCode,
                date: _this.form.date,
                remark: _this.form.remark,
                tradeIds: _this.form.tradeIds,
                refundIds: _this.form.refundIds,
                selfRefundIds: _this.form.selfRefundIds,
            };
            UrlUtils.PostRemote(this, this.url.save, _params);
        },
        handleSelectDeptOrDate() {
            this.meta.staffs = [];
            this.form.cashierCode = '';
            this.sureDeptHaveNoSyncTrade();
            this.initData();
            this.queryStaff();
        },
        sureDeptHaveNoSyncTrade() {
            const url = this.url.afterSaveQuery + this.form.deptCode;
            Util.queryTable(
                this,
                url,
                null,
                (data) => {
                    this.disableSave = false;
                },
                () => {
                    this.disableSave = true;
                }
            );
        },
        handleSelectStaff() {
            this.initData();
            this.querySettleData();
        },
        querySettleData() {
            const _this = this;
            if (_this.form.deptCode && _this.form.date) {
                const _params = {
                    params: {
                        deptCode: _this.form.deptCode,
                        cashier: _this.form.cashierCode,
                        date: _this.form.date,
                    },
                };
                Util.queryTable(_this, _this.url.queryDetail, _params, (data) => {
                    _this.form.tradeCount = data.data.tradeCount;
                    _this.form.refundCount = data.data.refundCount;
                    _this.form.totalOriginalPrice = _this.fmtMoney(data.data.totalOriginalPrice);
                    _this.form.totalFavour = _this.fmtMoney(data.data.totalFavour);
                    _this.form.totalFloor = _this.fmtMoney(data.data.totalFloor);
                    _this.form.totalPay = _this.fmtMoney(data.data.totalPay);
                    _this.form.needRefund = _this.fmtMoney(data.data.needRefund);
                    _this.form.refundPreference = _this.fmtMoney(data.data.refundPreference);
                    _this.form.totalRefund = _this.fmtMoney(data.data.totalRefund);
                    _this.form.selfRefundCount = data.data.selfRefundCount;
                    _this.form.totalSelfRefund = _this.fmtMoney(data.data.totalSelfRefund);
                    _this.form.tradeIds = data.data.tradeIds || [];
                    _this.form.refundIds = data.data.refundIds || [];
                    _this.form.selfRefundIds = data.data.selfRefundIds || [];
                    _this.tableData = data.data.pays;
                });
            } else {
                _this.$message.error('请选择门店和结算日期');
            }
        },
        queryStaff() {
            const _this = this;
            if (_this.canChangeCashier && _this.form.date && _this.form.deptCode) {
                const _staffParams = { params: { deptCode: _this.form.deptCode, date: _this.form.date } };
                Util.queryTable(_this, _this.url.queryStaff, _staffParams, (data) => {
                    _this.meta.staffs = data.data;
                    if (data.data.length == 1) {
                        _this.form.cashierCode = data.data[0].code;
                    }
                    _this.querySettleData();
                });
            } else {
                this.querySettleData();
            }
        },
        initData() {
            this.form.tradeCount = '';
            this.form.refundCount = '';
            this.form.totalOriginalPrice = '';
            this.form.totalFavour = '';
            this.form.totalFloor = '';
            this.form.totalPay = '';
            this.form.needRefund = '';
            this.form.refundPreference = '';
            this.form.totalRefund = '';
            this.form.selfRefundCount = '';
            this.form.totalSelfRefund = '';
            this.form.remark = '';
            this.form.tradeIds = [];
            this.form.refundIds = [];
            this.form.selfRefundIds = [];
            this.tableData = [];
        },
    },
};
</script>

<style scoped>
.dailySettle_detail .el-form-item {
    margin-bottom: 0;
}
</style>
